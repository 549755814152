module.exports = {
    seo: {
        title : "מאמר - פיתוח אבטיפוס - Jenko",
        description:  ""
      },
  intro: {
      title: "פיתוח אבטיפוס"
  },
  articleHeader: {
      desc: "ביצעתם אפיון מקיף והעברתם אותו בדיקת היתכנות יסודית. קיבלתם תמונת מצב ממוקדת אודות הקהל שלכם, השוק, הרווחים האפשריים ועלויות הייצור והפיתוח. אתם מוכנים לשלב בניית אב הטיפוס!"
  },
  secP: {
      title: "בניית אבטיפוס",
      p: [
          'אין ספק שכל השלבים בדרך מהפיכת רעיון גולמי לכדי מוצר ממשי הם חשובים. ובכל זאת, שלב ייצור אב הטיפוס הוא אולי החשוב ביותר. מדוע? ',
          'אבטיפוס הוא החוליה המקשרת שבין השלב התיאורטי לשלב המעשי. האלמנט שממיר את כל מה שקורה "על הנייר" או בהדמיית מחשב לכדי מוצר מוחשי שמוכן לשלב הייצור ההמוני. אב-הטיפוס מהותי כי הוא ממחיש לנו את הרעיון בתצורתו המעשית ולא תמיד הדברים מתנהגים בפרקטיקה כפי שדמיינו בתיאוריה. הוא מאפשר לנו לבחון את תועלתו של המוצר הלכה למעשה ולאתר את הפגמים על מנת שנוכל לתקן אותם בזמן, מבלי להפסיד סכומי עתק עבור עלויות התיקון. בעזרת אבטיפוס אנו מבצעים בחינה מעמיקה של בחירות חשובות לקראת תהליך הייצור. בחירות של סוגי חומרים, הליכי ייצור, בתי מלאכה נבחרים לייצור ועוד. ובל נשכח שאת אב הטיפוס אתם עומדים להציג בפגישות הגורליות שלכם עם המשקיעים.',
          'דינו של ייצור אבטיפוס הוא כדינה של חזרה גנרלית: כמה שיהיה מדויק יותר – כך ישקף בצורה נאמנה יותר את המציאות של השלב הבא. אי דיוקים גורמים לפספוסים שעלולים לגבות מחיר כבד במונחי המשאבים הכי יקרים שלכם: זמן, כסף, אנרגיה. על כן, עליכם להיות בטוחים שבחרתם בחברה הכי מקצועית ומהימנה מבחינתכם לביצוע המשימה'
      ]
  },
  thrdP: {
      title: "איך בונים אבטיפוס",
      p: [
          'ישנם מספר שלבים בפיתוח אבטיפוס.  ',
          'בשלב הראשוני מגדירים דרישות. ממציא המוצר מגדיר מפרט דרישות לקוח ואילו המומחים בתחומיהם השונים מייצרים אפיון על סמך הדרישות והממצאים. המהנדסים בתחומיהם השונים מגבשים דרישות הנדסיות, המעצבים מגבשים דרישות עיצוב ומהנדסים המוכשרים לכך מגבשיםדרישות תכן שינחו את אנשי המקצוע השונים בפרויקט כיצד לבצע אינטגרציה בין התחומים ולהפוך את האפיון למוצר, או במילים אחרות, להוציא את כל זה לפועל!',
          "ברגע שהוחלט על הטיימינג המדויק, כדאי להכין את השטח לגיוס משקיע. אין לדעת מתי המשקיע הנכון יתדפק על מפתן הדלת ולעיתים הדבר קורה מהר יותר מכפי שדמיינתם. אתם רוצים להיות מוכנים כשזה קורה אחרת אתם עלולים לפספס את ההזדמנות. את ההכנה לגיוס משקיעים אפשר לחלק לשלושה רבדים: עבודה הכנה פנימית, עבודת הכנה חיצונית ולמידה על המשקיעים.",
          'דרישות התכן ילוו את המוצר בהמשך כאשר יצא החוצה בשלב הייצור ההמוני. כך מתהווה לה מפת דרך מדויקת בדרך לייצור אבטיפוס. אב הטיפוס נמשל ל"אב הקדמון" של המוצר הסופי שיימכר בגרסאות המתקדמות. בשלב הזה ניתן לבדוק את האפשרות לרשום פטנט. יש לציין כי לא תמיד זה אפשרי ובמידה וכן אפשרי, תעמוד בפניכם ההחלטה האם לשאת בעלויות שכרוכות בכך.'
      ]
  },
  frthP: {
      title: "עלות ייצור אבטיפוס",
      p: "עלויות הייצור של אבי-הטיפוס השונים משתנות בהתאם לסוג המוצר. זה יכול להיות מוצר מבוסס תוכנה, חומרה, מכשיר לשימוש ביתי, תעשייתי, רפואי, הנדסי, סלולרי, מבוסס חשמל או אלחוטי והרשימה עוד ארוכה. ישנן חברות שערוכות לייצר אבי טיפוס בתוך החברה מא' ועד ת' בעוד שאחרות עובדות עם שותפים המייצרים את המוצר בכללותו או חלקים ממנו עבורן. כדאי לבחור בחברה שבבעלותה ציוד טכנולוגי מתקדם ובפרט מדפסות תלת ממד ייעודיות שתחסוכנה משמעותית עלויות בשלב ההכנה לייצור המוני. מדפסות אלה ערוכות להדפסה תעשייתית מתקדמת ובעזרתן מתקבל מוצר שזהה בממדיו ובחזותו לתוצר הסופי המבוקש. השימוש בהדפסה תלת ממדית מאפשר להעמיק בעיצוב המוצר לאור היכולת לבצע ניסיונות חוזרים ונשנים עד שמתקבל התוצר המושלם. כך למעשה נמנע הצורך להשקיע סכומי עתק ביצירת אבטיפוס מהחומרים הממשיים שנבחרו לייצור ההמוני."
  },
  dir: 'rtl'
};