import * as React from "react";
import Layout from "../../../layouts/he";
import PageHead from "../../../components/shared/PageHead";
import Intro from "../../../components/shared/Intro";
import * as styles from "../../../styles/pages/articles/index.module.css";
import content from "../../../data/content/articles/prototype-dev/article-he";
import tech from "../../../assets/images/articles/tech2.svg";
import computers from "../../../assets/images/articles/computers.svg";
import money from "../../../assets/images/articles/money2.svg";

const PrototypeDev = (props) => {
  const {
    sIntroSection,
    sIntro,
    sArticleContainer,
    sPD1Sec,
    sPD2Sec,
    sPD2SecH2,
    sPD2SecSplit,
    sPD3Sec,
    sPD4Sec,
  } = styles;
  const { seo, intro, articleHeader, secP, thrdP, frthP, dir } = content;
  return (
    <Layout location={props.location} lang="he">
      <PageHead pageHeadData={seo} />
      <article lang="he" className="article" dir={dir}>
        <Intro content={intro} styles={{ sIntroSection, sIntro }} />
        <div className={sArticleContainer}>
          <section className={sPD1Sec}>
            <p>{articleHeader.desc}</p>
          </section>
          <section className={sPD2Sec}>
            <div className={sPD2SecH2}>
              <h2>{secP.title}</h2>
            </div>
            <div>
              <div className={sPD2SecSplit}>
                <div>
                  <p>{secP.p[0]}</p>
                  <p>{secP.p[1]}</p>
                </div>
                <img src={tech} alt="tech" />
              </div>
              <p>{secP.p[2]}</p>
            </div>
          </section>
          <section className={sPD3Sec}>
            <div>
              <img src={computers} alt="computers" />
            </div>
            <div>
              <h2>{thrdP.title}</h2>
              {thrdP.p.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          </section>
          <section className={sPD4Sec}>
            <div>
              <h2>{frthP.title}</h2>
              <p>{frthP.p}</p>
            </div>
            <div>
              <img src={money} alt="money" />
            </div>
          </section>
        </div>
      </article>
    </Layout>
  );
};

export default PrototypeDev;
